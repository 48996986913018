/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type GetBankTransferRecipientsIntakeStatusesItem = typeof GetBankTransferRecipientsIntakeStatusesItem[keyof typeof GetBankTransferRecipientsIntakeStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetBankTransferRecipientsIntakeStatusesItem = {
  awaiting_recipient: 'awaiting_recipient',
  pending_verification: 'pending_verification',
  bank_account_verified: 'bank_account_verified',
  verification_failed: 'verification_failed',
} as const;
