/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type EventType = typeof EventType[keyof typeof EventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventType = {
  card_locked: 'card_locked',
  card_unlocked: 'card_unlocked',
  card_frozen: 'card_frozen',
  card_unfrozen: 'card_unfrozen',
  card_reassociated: 'card_reassociated',
  card_reissued: 'card_reissued',
  card_closed: 'card_closed',
  virtual_card_activated: 'virtual_card_activated',
  virtual_card_resent: 'virtual_card_resent',
  card_pin_set: 'card_pin_set',
  card_merchant_rule_added: 'card_merchant_rule_added',
  card_merchant_rule_removed: 'card_merchant_rule_removed',
  card_tag_added: 'card_tag_added',
  card_tag_removed: 'card_tag_removed',
  card_funds_loaded: 'card_funds_loaded',
  card_funds_transferred: 'card_funds_transferred',
  card_funds_withdrawn_to_funding_account: 'card_funds_withdrawn_to_funding_account',
  card_order_placed: 'card_order_placed',
  card_spend_rule_added: 'card_spend_rule_added',
  card_spend_rule_removed: 'card_spend_rule_removed',
  user_added: 'user_added',
  user_removed: 'user_removed',
  cardholder_email_updated: 'cardholder_email_updated',
  business_settings_created: 'business_settings_created',
  business_settings_updated: 'business_settings_updated',
  recipient_created: 'recipient_created',
  recipient_updated: 'recipient_updated',
  bank_account_added: 'bank_account_added',
  bank_account_deleted: 'bank_account_deleted',
  bank_transfer_initiated: 'bank_transfer_initiated',
  bank_account_submitted_for_verification: 'bank_account_submitted_for_verification',
  bank_account_verified: 'bank_account_verified',
  bank_account_verification_failed: 'bank_account_verification_failed',
  metadata_added: 'metadata_added',
  metadata_updated: 'metadata_updated',
  metadata_deleted: 'metadata_deleted',
} as const;
