import {
  Business,
  BusinessProgram,
  Cardholder,
  SpendRule,
  SpendRuleWithProgramName,
  User,
} from '@/api/models';
import { BusinessSettings } from '@/interfaces/BusinessSettings';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const Business = {};

const store = createStore({
  state: {
    baseBusiness: {} as Business,
    business: {} as Business,
    businessSettings: {} as BusinessSettings,
    cardholder: {} as Cardholder,
    programs: [] as BusinessProgram[],
    user: {} as User,
    currentSpendRule: {} as SpendRule,
    businessSpendRules: [] as SpendRule[],
  },
  mutations: {
    SET_BASE_BUSINESS(state, baseBusiness) {
      state.baseBusiness = baseBusiness;
    },
    SET_BUSINESS(state, business) {
      state.business = business;
    },
    SET_BUSINESS_SETTINGS(state, businessSettings) {
      state.businessSettings = businessSettings;
    },
    SET_CARDHOLDER(state, cardholder) {
      state.cardholder = cardholder;
    },
    SET_PROGRAMS(state, programs) {
      state.programs = programs;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_CURRENT_SPEND_RULE(state, currentSpendRule) {
      state.currentSpendRule = currentSpendRule;
    },
    SET_BUSINESS_SPEND_RULES(state, businessSpendRules) {
      state.businessSpendRules = businessSpendRules;
    },
  },
  actions: {
    updateBaseBusiness({ commit }, baseBusiness) {
      commit('SET_BASE_BUSINESS', baseBusiness);
    },
    updateBusiness({ commit }, business) {
      commit('SET_BUSINESS', business);
    },
    updateBusinessSettings({ commit }, businessSettings) {
      commit('SET_BUSINESS_SETTINGS', businessSettings);
    },
    updateCardholder({ commit }, cardholder) {
      commit('SET_CARDHOLDER', cardholder);
    },
    updatePrograms({ commit }, programs) {
      commit('SET_PROGRAMS', programs);
    },
    updateUser({ commit }, user) {
      commit('SET_USER', user);
    },
    updateCurrentSpendRule({ commit }, currentSpendRule) {
      commit('SET_CURRENT_SPEND_RULE', currentSpendRule);
    },
    updateBusinessSpendRules({ commit }, businessSpendRules) {
      commit('SET_BUSINESS_SPEND_RULES', businessSpendRules);
    },
    resetState({ commit }) {
      commit('SET_BASE_BUSINESS', {} as Business);
      commit('SET_BUSINESS', {} as Business);
      commit('SET_BUSINESS_SETTINGS', {} as BusinessSettings);
      commit('SET_CARDHOLDER', {} as Cardholder);
      commit('SET_PROGRAMS', [] as BusinessProgram[]);
      commit('SET_USER', {} as User);
      commit('SET_CURRENT_SPEND_RULE', {} as SpendRule);
      commit('BUSINESS_SPEND_RULES', {} as SpendRuleWithProgramName[]);
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
