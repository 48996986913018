/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type SpendRuleType = typeof SpendRuleType[keyof typeof SpendRuleType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpendRuleType = {
  velocity: 'velocity',
  merchant_allow: 'merchant_allow',
  merchant_block: 'merchant_block',
} as const;
