/* eslint-disable max-len */
/* eslint-disable prefer-regex-literals */
import {
  CardOrderCardOrderCardType,
  CardOrderShipmentMethod,
  OrderAndShipCardsParamsShipmentMethod,
  TransactionEvent,
} from '@/api/models';
import 'moment-timezone';
import moment from 'moment';
import { reactive } from 'vue';
import EmailValidator from 'email-validator';
import i18n from '@/i18n';
import { RouteLocationNormalized } from 'vue-router';
import SupportedLanguages from '@/global/util';
import { Tag } from '@/interfaces/Tag';
import store from '../store/store';

export interface ApiError extends Error {
  code: number;
  msg?: string;
}

const globalMethods = reactive({
  getRoutePath(status: string, id: string, scope: string): string {
    const stateRouteMap = new Map<string, string>([
      ['business_info', 'businessInfo'],
      ['authorized_person', 'authorizedPersonInfo'],
      ['submit_application', 'submitConfirmation'],
      ['waiting_for_beneficial_owners', 'beneficialOwnerStatus'],
      ['application_submitted', 'submitConfirmation'],
      ['active', 'cards'],
    ]);
    let routeName = stateRouteMap.get(status);
    if (routeName === undefined) {
      routeName = 'landingPage';
    }
    if (routeName === 'cards') {
      if (scope === 'super') {
        routeName = 'programs';
      }
    }
    return routeName;
  },

  shipping: {
    shippingTypes: [
      {
        id: 'USPS_GROUND',
        name: 'USPS Ground',
        trackingBaseUrl: 'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=',
      },
      {
        id: 'USPS_PRIORITY',
        name: 'USPS Priority',
        trackingBaseUrl: 'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=',
      },
      {
        id: 'USPS_EXPRESS',
        name: 'USPS Express',
        trackingBaseUrl: 'https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=',
      },
      {
        id: 'UPS_GROUND',
        name: 'UPS Ground',
        trackingBaseUrl: 'https://www.ups.com/track?loc=en_US&tracknum=',
      },
      {
        id: 'UPS_SECOND_DAY',
        name: 'UPS Second Day',
        trackingBaseUrl: 'https://www.ups.com/track?loc=en_US&tracknum=',
      },
      {
        id: 'UPS_NEXT_DAY',
        name: 'UPS Next Day',
        trackingBaseUrl: 'https://www.ups.com/track?loc=en_US&tracknum=',
      },
    ],
    formatShipmentMethod(
      shipMethod?: CardOrderShipmentMethod,
      orderType?: CardOrderCardOrderCardType
    ): string {
      if (orderType === 'virtual') {
        return 'Virtual';
      }
      const index = this.shippingTypes.map((e) => e.id).indexOf(shipMethod || '');
      if (index < 0) {
        return '';
      }
      return this.shippingTypes[index].name;
    },
    getTrackingUrl(shipMethod?: CardOrderShipmentMethod): string {
      const index = this.shippingTypes.map((e) => e.id).indexOf(shipMethod || '');
      if (index < 0) {
        return '';
      }
      return this.shippingTypes[index].trackingBaseUrl;
    },
  },

  userReadable: {
    mapAndFormatDeclineReasons(declineReason: string, responseCode: string): string {
      switch (declineReason) {
        case 'CA_DECLINED_MERCHANT':
          return 'Invalid Merchant';
        case 'CA_DECLINED_VELOCITY':
          return 'Velocity Limit Exceeded';
        case '':
          if (responseCode === 'CA_DECLINED') {
            return 'Invalid Merchant';
          }
          return 'Declined';
        default:
          return this.screamingSnakeCaseToTitleCase(declineReason);
      }
    },

    digitToOrdinal(input: string): string {
      const inputAsDigit = Number(input);
      if (typeof inputAsDigit === 'undefined') {
        return '';
      }
      if (inputAsDigit % 100 >= 11 && inputAsDigit % 100 <= 13) {
        return `${inputAsDigit}th`;
      }

      switch (inputAsDigit % 10) {
        case 1:
          return `${inputAsDigit}st`;
        case 2:
          return `${inputAsDigit}nd`;
        case 3:
          return `${inputAsDigit}rd`;
        default:
          return `${inputAsDigit}th`;
      }
    },
    writtenOutFrequency(frequency?: string, nextLoad?: string, specialCase?: string): string {
      const parsedDate = nextLoad?.split('T')[0].split('-') || [];
      this.digitToOrdinal(parsedDate[2] || '');

      const weekdayOptions = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];

      const d = new Date(nextLoad || '');
      const dayOfWeek = weekdayOptions[d.getDay()];

      if (specialCase === 'last_of_month') {
        return `This load will happen on the last day of the month. The next load date will be on ${globalMethods.formatting.formatDate(
          nextLoad || '',
          'date+time'
        )}.`;
      }
      if (frequency?.toLowerCase() === 'daily') {
        return `This load will happen every day at 3 A.M. EST. The next load date will be on ${globalMethods.formatting.formatDate(
          nextLoad || '',
          'date+time'
        )}.`;
      }
      if (frequency?.toLowerCase() === 'weekly') {
        return `This load will happen weekly on ${dayOfWeek}. The next load date will be on ${globalMethods.formatting.formatDate(
          nextLoad || '',
          'date+time'
        )}.`;
      }
      if (frequency?.toLowerCase() === 'monthly') {
        return `This load will happen every month on the ${this.digitToOrdinal(
          parsedDate[2] || ''
        )}. The next load date will be on ${globalMethods.formatting.formatDate(
          nextLoad || '',
          'date+time'
        )}.`;
      }
      return '';
    },
    writtenOutFrequencyPresentTense(
      date?: string,
      frequency?: string,
      specialCase?: string
    ): string {
      const parsedDate = date?.split('T')[0].split('-') || [];
      this.digitToOrdinal(parsedDate[2] || '');

      const weekdayOptions = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];

      const d = new Date(date || '');
      const dayOfWeek = weekdayOptions[d.getDay()];

      if (specialCase === 'last_of_month') {
        return 'Card loads occur on the last day of the month.';
      }
      if (frequency?.toLowerCase() === 'daily') {
        return 'Card loads occur every day at 3 A.M. EST.';
      }
      if (frequency?.toLowerCase() === 'weekly') {
        return `Card loads occur weekly on ${dayOfWeek}.`;
      }
      if (frequency?.toLowerCase() === 'monthly') {
        return `Card loads occur every month on the ${this.digitToOrdinal(parsedDate[2] || '')}.`;
      }
      return '';
    },
    screamingSnakeCaseToTitleCase(input: string): string {
      return input
        .replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ') // Split into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
        .join(' '); // Join the words back together
    },

    getTransactionAmountFromTransactionEvent(transaction: TransactionEvent): string {
      if (transaction.transactionEventStatus === 'declined') {
        return `-${globalMethods.formatting.formatAmount(
          transaction.requestedAmount?.value || '0'
        )}`;
      }
      const sign = transaction.transactionEventStatus === 'refunded' ? '+' : '-';
      return sign + globalMethods.formatting.formatAmount(transaction.approvedAmount?.value || '0');
    },
  },

  autoloads: {
    getFirstValidStartDate(): string {
      const d = new Date();
      // if local timezone to utc > 7 difference and UTC time is after 6:00 am to allow 1 hour buffer, add 2 days)
      if (
        globalMethods.conversions.getTimezoneOffsetInHours() >= 7 &&
        d.getUTCHours() >= 6 &&
        d.getDate() !== d.getUTCDate()
      ) {
        d.setDate(d.getDate() + 2);
      } else {
        d.setDate(d.getDate() + 1);
      }
      return globalMethods.formatting.formatDateForCalendarPicker(d);
    },

    getFirstValidEndDate(startDate: string): string {
      if (startDate === '') {
        const d = new Date();
        if (
          globalMethods.conversions.getTimezoneOffsetInHours() >= 7 &&
          d.getUTCHours() >= 6 &&
          d.getDate() !== d.getUTCDate()
        ) {
          d.setDate(d.getDate() + 3);
        } else {
          d.setDate(d.getDate() + 2);
        }
        return globalMethods.formatting.formatDateForCalendarPicker(d);
      }
      const d = globalMethods.formatting.getDateAndTimeInLocalTimeZoneDateType(startDate);
      d.setDate(d.getDate() + 1);
      return globalMethods.formatting.formatDateForCalendarPicker(d);
    },
  },

  formatting: {
    getDateAndTimeInLocalTimeZone(date: string): string {
      if (date === '') {
        return '';
      }
      const userTimeZone = moment.tz.guess(); // user's timezone
      const a = moment.tz(date, userTimeZone !== undefined ? userTimeZone : 'America/New_York');
      const timeZoneAdjustedDate = a.format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      return timeZoneAdjustedDate;
    },

    getDateAndTimeInLocalTimeZoneDateType(date: string): Date {
      const userTimeZone = moment.tz.guess(); // user's timezone
      const a = moment.tz(date, userTimeZone !== undefined ? userTimeZone : 'America/New_York');
      const timeZoneAdjustedDate = a.format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      return new Date(timeZoneAdjustedDate);
    },

    formatDate(date: string, format?: string) {
      let formattedDate: string;
      const calendarMonthTranslation: { [key: number]: string } = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      };
      if (date === '') {
        return '';
      }
      if (format === 'MMYY') {
        const parsedDate = date.split('T')[0].split('-');
        formattedDate = `${parsedDate[1]}/${parsedDate[0]}`;
      } else if (format === 'DDMMYY') {
        const parsedDate = date.split('T')[0].split('-');
        formattedDate = `${
          calendarMonthTranslation[Number(parsedDate[1])]
        } ${globalMethods.userReadable.digitToOrdinal(parsedDate[2])}, ${parsedDate[0]}`;
      } else if (format === 'date+time') {
        const parsedTime: string = date.split('T')[0];
        const parsedYMD: string[] = parsedTime.split('-');
        formattedDate = `${
          calendarMonthTranslation[Number(parsedYMD[1])]
        } ${globalMethods.userReadable.digitToOrdinal(parsedYMD[2])}, ${parsedYMD[0]}`;
      } else if (format === 'YYYY-MM-DD') {
        const parsedTime: string = date.split('T')[0];
        formattedDate = parsedTime;
      } else if (format === 'M DD, YYYY') {
        const parsedDate = date.split('T')[0].split('-');
        formattedDate = `${calendarMonthTranslation[Number(parsedDate[1])]} ${parsedDate[2]}, ${
          parsedDate[0]
        }`;
      } else if (format === 'M D HH:MM am/pm') {
        const parsedDateTime = new Date(date);
        const month = calendarMonthTranslation[parsedDateTime.getMonth() + 1]; // Month starts from 0 in JavaScript
        const day = parsedDateTime.getDate();
        let hour = parsedDateTime.getHours();
        const minute = parsedDateTime.getMinutes();
        const ampm = hour >= 12 ? 'pm' : 'am';

        // Convert hour to 12-hour format
        if (hour > 12) {
          hour -= 12;
        } else if (hour === 0) {
          hour = 12;
        }

        formattedDate = `${month} ${day} ${hour}:${minute.toString().padStart(2, '0')}${ampm}`;
      } else if (format === 'M/D/YYYY HH:MM AM/PM') {
        const parsedDateTime = new Date(date);
        const month = parsedDateTime.getMonth() + 1; // Month starts from 0 in JavaScript
        const day = parsedDateTime.getDate();
        const year = parsedDateTime.getFullYear();
        let hour = parsedDateTime.getHours();
        const minute = parsedDateTime.getMinutes();
        const ampm = hour >= 12 ? 'PM' : 'AM';

        // Convert hour to 12-hour format
        if (hour > 12) {
          hour -= 12;
        } else if (hour === 0) {
          hour = 12;
        }

        formattedDate = `${month}/${day}/${year} ${hour}:${minute
          .toString()
          .padStart(2, '0')}${ampm}`;
      } else {
        formattedDate = '';
      }
      return formattedDate;
    },
    formatTime(date?: string): string {
      const userTimeZone = moment.tz.guess(); // user's timezone
      const a = moment.tz(date, userTimeZone !== undefined ? userTimeZone : 'America/New_York');
      return a.format('h:mm:ss A z');
    },

    formatDateAndTime(date: string): { date: string; time: string } {
      if (date === '') {
        return { date: '', time: '' };
      }
      const dateInLocalZone = this.getDateAndTimeInLocalTimeZone(date);

      const tempDate = this.formatDate(dateInLocalZone, 'date+time');
      const tempTime = this.formatTime(dateInLocalZone);
      return { date: tempDate, time: tempTime };
    },

    capitalize(input?: string): string {
      if (input !== undefined && input !== '') {
        return input[0].toUpperCase() + input.slice(1);
      }
      return input || '';
    },
    formatSnakeCase(input?: string) {
      if (input === undefined) {
        return '';
      }
      return input
        .split('_')
        .join(' ')
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    },
    formatAmount(input: string): string {
      const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return USDollar.format(parseFloat(input));
    },

    // Date comes in M/D/YYYY H:MM:SS or MM/DD/YYYY H:MM:SS
    formatDateForCalendarPicker(date: Date): string {
      const dateAsString = date.toLocaleString().split(',')[0];
      const splitMonthDayYear = dateAsString.split('/');
      if (splitMonthDayYear[0].length === 1) splitMonthDayYear[0] = `0${splitMonthDayYear[0]}`;
      if (splitMonthDayYear[1].length === 1) splitMonthDayYear[1] = `0${splitMonthDayYear[1]}`;

      // Needs to return exactly as YYYY-MM-DD
      return `${splitMonthDayYear[2]}-${splitMonthDayYear[0]}-${splitMonthDayYear[1]}`;
    },

    // Date comes in M/D/YYYY H:MM:SS or MM/DD/YYYY H:MM:SS
    formatStringForCalendarPicker(date: Date): string {
      const dateAsString = date.toLocaleString().split(',')[0];
      const splitMonthDayYear = dateAsString.split('/');
      if (splitMonthDayYear[0].length === 1) splitMonthDayYear[0] = `0${splitMonthDayYear[0]}`;
      if (splitMonthDayYear[1].length === 1) splitMonthDayYear[1] = `0${splitMonthDayYear[1]}`;

      // Needs to return exactly as YYYY-MM-DD
      return `${splitMonthDayYear[2]}-${splitMonthDayYear[0]}-${splitMonthDayYear[1]}`;
    },

    stringToUTCString(input: string) {
      const date = new Date(input);

      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    },

    convertToUTCAndFormatDate(inputDateString: string) {
      const date = this.stringToUTCString(inputDateString);
      return this.formatDate(date, 'date+time');
    },

    formatMerchantCategory(merchantData?: string): string {
      if (merchantData === undefined) {
        return '';
      }
      return merchantData
        .split('_')
        .join(' ')
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    },

    formatDepositType(
      depositType: string,
      programId?: string,
      to?: boolean,
      isSuper?: boolean
    ): string {
      const program = store.state.programs.find((p) => p.id === programId);

      switch (depositType) {
        case 'ach':
          return 'ACH';
        case 'super_to_program':
          if (isSuper) {
            return `Outgoing Transfer: ${program?.name}`;
          }
          return 'Incoming Transfer: Superbusiness';
        case 'program_to_super':
          if (isSuper) {
            return `Incoming Transfer: ${program?.name}`;
          }
          return 'Outgoing Transfer: Superbusiness';
        case 'program_to_program':
          if (to) {
            if (program?.name) {
              return `Outgoing Transfer: ${program.name}`;
            }
            return 'Outgoing Transfer';
          }

          if (program?.name) {
            return `Incoming Transfer: ${program.name}`;
          }
          return 'Incoming Transfer';
        default:
          return globalMethods.formatting.capitalize(depositType);
      }
    },
    formatPhoneNumber(phone: string): string {
      if (!phone) return ''; // Return empty if the phone is not provided
      const cleaned = String(phone).replace(/\D/g, ''); // Remove all non-numeric characters
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return phone; // If it's not a valid 10-digit number, return it unformatted
    },
  },
  validation: {
    isUUID(str: string): boolean {
      const uuidRegex =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      return uuidRegex.test(str);
    },

    validatePassword(
      pw: string,
      confirmPw?: string
    ): { validate: boolean; error: string | undefined } {
      const regExp = /^(?=.*\d)(?=.*[^a-zA-Z0-9]).{8}/;
      if (!regExp.test(pw)) {
        return { validate: false, error: i18n.global.t('passwordInvalidError') };
      }
      if (confirmPw && pw !== confirmPw) {
        return { validate: false, error: i18n.global.t('passwordMismatchError') };
      }
      return { validate: true, error: undefined };
    },
    validatePhoneNumber(pn: string): boolean {
      const regTenDigitNumber = new RegExp('^\\d{10}$');
      return regTenDigitNumber.test(pn);
    },

    /* The address validations are based on: https://highnote.com/docs/reference/input_object/AddressInput */

    validateAddressOne(input: string): { value: boolean; error: string } {
      const regAddressOne = new RegExp(
        '^(?:((\\S([^pPOo])+)|(?:[0-9]+)))\\s(?:[0-9A-Za-z\\.]|[^\\S\\r\\n])+$'
      );
      const regPOBox = new RegExp(
        '\\b[P|p]*(OST|ost)*\\.*\\s*[O|o|0]*(ffice|FFICE)*\\.*\\s*[B|b][O|o|0][X|x]\\b'
      );
      if (regAddressOne.test(input) && !regPOBox.test(input)) {
        return { value: true, error: '' };
      }
      if (regPOBox.test(input)) {
        return { value: false, error: 'Invalid Address One. This address cannot be a PO Box.' };
      }
      if (!regAddressOne.test(input)) {
        return { value: false, error: 'Invalid Address One.' };
      }
      return { value: false, error: 'Invalid Address One. Please try again.' };
    },

    validateAddressTwo(input: string): { value: boolean; error: string } {
      if (input.length === 0) {
        return { value: true, error: '' };
      }
      const regAddressTwo = new RegExp("^[a-zA-Z\\d',. \\-#]+(([',. \\-#][a-zA-Z ])?[a-zA-Z.]*)*$");
      const regPOBox = new RegExp(
        '\\b[P|p]*(OST|ost)*\\.*\\s*[O|o|0]*(ffice|FFICE)*\\.*\\s*[B|b][O|o|0][X|x]\\b'
      );
      const regSpecialChar = new RegExp('^[A-Za-z0-9 ]+$');
      if (regAddressTwo.test(input) && !regPOBox.test(input)) {
        return { value: true, error: '' };
      }
      if (regPOBox.test(input)) {
        return { value: false, error: 'Invalid Address Two. This address cannot be a PO Box.' };
      }
      if (!regSpecialChar.test(input)) {
        return { value: false, error: 'Invalid Address Two. No special characters allowed' };
      }
      if (!regAddressTwo.test(input)) {
        return { value: false, error: 'Invalid Address Two.' };
      }
      return { value: false, error: 'Invalid Address Two. Please try again.' };
    },

    validateCity(input: string): { value: boolean; error: string } {
      const regLettersPeriodDash = new RegExp('^[A-Za-z0-9 .-]+$');
      if (input.length === 0 || input.length >= 256) {
        return {
          value: false,
          error: `Invalid City: City must be between 1 and 255 characters. The current length is: ${input.length}`,
        };
      }
      if (!regLettersPeriodDash.test(input)) {
        return { value: false, error: 'Invalid City: No special characters allowed' };
      }
      return { value: true, error: '' };
    },

    validateState(input: string): { value: boolean; error: string } {
      const regLettersPeriodDash = new RegExp(
        '^(?:AL|AK|AS|AZ|AR|CA|CO|CT|DE|DC|FM|FL|GA|GU|HI|ID|IL|IN|IA|KS|KY|LA|ME|MH|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|MP|OH|OK|OR|PW|PA|PR|RI|SC|SD|TN|TX|UT|VT|VI|VA|WA|WV|WI|WY)$'
      );

      if (!regLettersPeriodDash.test(input)) {
        return { value: false, error: 'Invalid State: Input needs to be a 2 digit state code.' };
      }
      return { value: true, error: '' };
    },

    validateZipCode(input: string): { value: boolean; error: string } {
      const regZipCode = new RegExp('^\\d{5}$');
      if (regZipCode.test(input)) {
        return { value: true, error: '' };
      }
      return {
        value: false,
        error: `Invalid Zip Code: Zip Code needs to be exactly 5 digits. Current amount: ${input.length}`,
      };
    },

    validateShipmentMethod(input: string): { value: boolean; error: string } {
      const validShipmentMethod = Object.values(OrderAndShipCardsParamsShipmentMethod).includes(
        input as any
      );
      if (!validShipmentMethod) {
        return {
          value: false,
          error:
            'Invalid Shipment Method: Needs to be USPS_PRIORITY,UPS_NEXT_DAY, or UPS_SECOND_DAY',
        };
      }
      return { value: true, error: '' };
    },

    validateAddressFirstName(input: string): { value: boolean; error: string } {
      const regSpecialChar = new RegExp('^[A-Za-z ]+$');
      if (input.length === 0 || input.length >= 256) {
        return {
          value: false,
          error: `Invalid First Name: First name must be between 1 and 255 characters. The current length is: ${input.length}`,
        };
      }

      if (input.indexOf(':') > -1) {
        return {
          value: false,
          error: "Invalid First Name: First name cannot contain ':' character",
        };
      }

      if (!regSpecialChar.test(input)) {
        return {
          value: false,
          error:
            'Invalid First Name: No special characters allowed. Letter accents are unfortunately not allowed. For a hyphen, use a space.',
        };
      }
      return { value: true, error: '' };
    },
    validateAddressLastName(input: string): { value: boolean; error: string } {
      const regSpecialChar = new RegExp('^[A-Za-z ]+$');

      if (input.length === 0 || input.length >= 256) {
        return {
          value: false,
          error: `Invalid Last Name: Last name must be between 1 and 255 characters. The current length is: ${input.length}`,
        };
      }
      if (!regSpecialChar.test(input)) {
        return {
          value: false,
          error:
            'Invalid Last Name: No special characters allowed. Letter accents are unfortunately not allowed. For a hyphen, use a space.',
        };
      }
      return { value: true, error: '' };
    },

    validateEmail(input: string): { value: boolean; error: string } {
      if (!EmailValidator.validate(input)) {
        return { value: false, error: 'Invalid Email' };
      }
      return { value: true, error: '' };
    },

    validateBankTransferRecipientName(input: string): { value: boolean; error: string } {
      const orumNameRegex =
        /^[a-zA-Z \-.'áéíóúÁÉÍÓÚñÑüÜàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛãõÃÕäëïöüÿÄËÏÖÜçÇčďěňřšťžČĎĚŇŘŠŤŽăĂåÅāēīūĀĒĪŪąĄęĘėĖżŻøØđĐ]+$/;

      if (!input || input.length === 0) {
        return { value: false, error: 'Name is required' };
      }

      if (!orumNameRegex.test(input)) {
        return { value: false, error: 'Name contains invalid characters' };
      }

      return { value: true, error: '' };
    },

    validateLoadAmount(input: string): { value: boolean; error: string } {
      const regLoad = new RegExp('^\\d*(\\.\\d{0,2})?$');
      const loadIsValid = regLoad.test(input.toString());

      if (!loadIsValid) {
        return { value: false, error: 'Invalid Load Amount: Invalid format for amount' };
      }
      return { value: true, error: '' };
    },

    validateBankTransferAmount(input: string): { value: boolean; error: string } {
      const regAmount = new RegExp('^\\d*(\\.\\d{0,2})?$');
      const amountIsValid = regAmount.test(input.toString());

      if (!amountIsValid) {
        return { value: false, error: 'Invalid Transfer Amount: Invalid format for amount' };
      }

      if (Number(input) > 50000) {
        return { value: false, error: 'Invalid Transfer Amount: Amount must be less than $50,000' };
      }
      return { value: true, error: '' };
    },
    validateTag(input: Tag): { value: boolean; error: string } {
      const regLoad = new RegExp('^\\d*(\\.\\d{0,2})?$');
      const loadIsValid = regLoad.test(input.toString());

      if (!loadIsValid) {
        return { value: false, error: 'Invalid Load Amount: Invalid format for amount' };
      }
      return { value: true, error: '' };
    },

    validateColorHex(input: string): { value: boolean; error: string } {
      const regHex = new RegExp('^#[0-9A-Fa-f]+$');
      const colorIsValid = regHex.test(input.toString());

      if (!colorIsValid && input.length !== 0) {
        return {
          value: false,
          error:
            'Color Is invalid: Color needs to be a valid hexadecimal color (#RRGGBB format) OR left blank',
        };
      }
      return { value: true, error: '' };
    },

    isApiError(error: unknown): error is ApiError {
      return (
        error instanceof Error &&
        'code' in error &&
        typeof (error as Error & { code: unknown }).code === 'number' &&
        (!('msg' in error) || typeof (error as Error & { msg: unknown }).msg === 'string')
      );
    },

    isLastDayOfMonth(input: string): boolean {
      if (input === '') {
        return false;
      }
      const inputDate = globalMethods.formatting.getDateAndTimeInLocalTimeZoneDateType(input);
      const month = inputDate.getMonth();
      inputDate.setDate(inputDate.getDate() + 1);
      return inputDate.getMonth() !== month;
    },

    isDay29To31(date: string): boolean {
      const day = date.split('-')[2];
      if (Number(day) > 28) {
        return true;
      }
      return false;
    },
  },

  conversions: {
    getTimezoneOffsetInHours(): number {
      return new Date().getTimezoneOffset() / 60;
    },

    getDateAndTimeInLocalTimeZone(date: string): string {
      if (date === '') {
        return '';
      }
      const userTimeZone = moment.tz.guess(); // user's timezone
      const a = moment.tz(date, userTimeZone !== undefined ? userTimeZone : 'America/New_York');
      const timeZoneAdjustedDate = a.format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      return timeZoneAdjustedDate;
    },

    // UTC to local

    // 2023-10-01T00:00:00Z -> 2023-09-30T20:00:00.0000-04:00
    utcStringToLocalString(input?: string): string {
      if (input === undefined) {
        return '';
      }
      const userTimeZone = moment.tz.guess(); // user's timezone
      const a = moment.tz(input, userTimeZone !== undefined ? userTimeZone : 'America/New_York');
      const timeZoneAdjustedDate = a.format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      return timeZoneAdjustedDate;
    },

    utcStringToLocalDate(input?: string): Date {
      if (input === undefined) {
        return new Date();
      }
      const userTimeZone = moment.tz.guess(); // user's timezone
      const a = moment.tz(input, userTimeZone !== undefined ? userTimeZone : 'America/New_York');
      const timeZoneAdjustedDate = a.format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      return new Date(timeZoneAdjustedDate);
    },

    // local to UTC

    // 2023-09-30T20:00:00.0000-04:00 -> 2023-10-01T00:00:00.000Z
    localStringToUtcString(input: string): string {
      const date = new Date(input);

      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
    },

    // 2023-10-07 with no time (but need to add local time stamp, ex. 21:00 EST)
    // output: 2023-10-08 with no time stamp but in UTC
    localStringToUtcBackendFormat(input: string): string {
      const now = new Date();
      const date = globalMethods.formatting.getDateAndTimeInLocalTimeZoneDateType(input);
      date.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
      const utc = this.localStringToUtcString(date.toString());
      return globalMethods.formatting.formatDate(utc, 'YYYY-MM-DD');
    },

    fixedPrecisionMultiply(num1: number, num2: number): number {
      const scaleFactor = 100;
      const num1InCents = Math.round(num1 * scaleFactor); // Convert num1 to integer representation (in cents)
      const num2InCents = Math.round(num2 * scaleFactor); // Convert num2 to integer representation (in cents)

      // Perform multiplication on the scaled numbers
      const resultInCents = (num1InCents * num2InCents) / (scaleFactor * scaleFactor);

      // Return the result in decimal format with 2 decimal places
      return Math.round(resultInCents * scaleFactor) / scaleFactor;
    },
  },
  ui: {
    getRandomHexColor(): string {
      const colors = [
        '#FF5733',
        '#6A0572',
        '#00FFFF',
        '#FFD700',
        '#008000',
        '#FF00FF',
        '#0000FF',
        '#800000',
        '#00FF00',
        '#FF69B4',
      ];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
  },
  languages: {
    getLanguageCodeFromUrl(route: RouteLocationNormalized): string {
      const languageCode = route.query.lang as string;
      return Object.values(SupportedLanguages).includes(languageCode as SupportedLanguages)
        ? languageCode
        : SupportedLanguages.ENGLISH; // Default to 'en' if the language is not supported
    },
  },
});
export default globalMethods;
