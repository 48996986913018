/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type CreateBankTransferRecipientParamsIntakeDeliveryType = typeof CreateBankTransferRecipientParamsIntakeDeliveryType[keyof typeof CreateBankTransferRecipientParamsIntakeDeliveryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBankTransferRecipientParamsIntakeDeliveryType = {
  email: 'email',
  none: 'none',
} as const;
