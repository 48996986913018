/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type ExternalBankAccountVerificationStatus = typeof ExternalBankAccountVerificationStatus[keyof typeof ExternalBankAccountVerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalBankAccountVerificationStatus = {
  pending: 'pending',
  verified: 'verified',
  failed: 'failed',
} as const;
