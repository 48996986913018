import { reactive } from 'vue';
import axios from 'axios';
import * as bulmaToast from 'bulma-toast';
import { Auth, getAuth, signOut } from 'firebase/auth';
import {
  AddMetadataParams,
  AddMetadataParamsType,
  BusinessProductType,
  BusinessProgram,
} from '@/api/models';
import { RouteLocationNormalized } from 'vue-router';
import { BusinessSettings } from '@/interfaces/BusinessSettings';
import { useAddMetadata } from '@/api/whimsy';
import * as FullStory from '@fullstory/browser';
import store from '../store/store';
import hasPermission from './permissions';
import methods from './methods';
import useIsPaybiltDomain from '../composables/useIsPaybiltDomain';

const { isPaybiltDomain } = useIsPaybiltDomain();
const hostName = isPaybiltDomain?.value ? 'Paybilt' : 'GiveCard';

const globalProperties = reactive({
  auth: {
    signOutUser(auth: Auth) {
      signOut(auth);
      store.dispatch('resetState');
    },

    handleSignOutAndPushToLogin(): void {
      const user = getAuth();
      signOut(user);
      window.location.href = '/login'; // can't user router.push because of circular dependencies
      store.dispatch('resetState');
    },

    handleSignOutAndPushToCardholderLanding(): void {
      const user = getAuth();
      signOut(user);
      window.location.href = '/cardholders'; // can't user router.push because of circular dependencies
      store.dispatch('resetState');
    },

    handleSignOutAndPushToPaybiltLanding(): void {
      const user = getAuth();
      signOut(user);
      window.location.href = '/paybilt'; // can't user router.push because of circular dependencies
      store.dispatch('resetState');
    },

    friendly401Message(): void {
      this.handleSignOutAndPushToLogin();
      bulmaToast.toast({
        message: `Authentication Error. Please try logging in again. If this problem persists, please contact ${hostName} support.`,
        type: 'is-danger',
        position: 'top-center',
        pauseOnHover: true,
        duration: 10000,
      });
    },

    friendly404AdminMessage(): void {
      this.handleSignOutAndPushToLogin();
      bulmaToast.toast({
        message: `Authentication Error. Please try logging in again. If this problem persists, please contact ${hostName} support.`,
        type: 'is-danger',
        position: 'top-center',
        pauseOnHover: true,
        duration: 10000,
      });
    },

    friendly404CardholderMessage(): void {
      this.handleSignOutAndPushToCardholderLanding();
      bulmaToast.toast({
        message: `Authentication Error. Please try logging in again. If this problem persists, please contact ${hostName} support.`,
        type: 'is-danger',
        position: 'top-center',
        pauseOnHover: true,
        duration: 10000,
      });
    },
  },
  store: {
    fetchAndUpdateBusinessSettings(businessId: string): BusinessSettings {
      let customBizSettings = {} as BusinessSettings;
      // Covenant House NY business id (and a dev program ID)
      if (
        businessId === '0994be4d-63fc-42c8-a729-28cc613379a4' ||
        store.state.baseBusiness.id === '0994be4d-63fc-42c8-a729-28cc613379a4'
      ) {
        customBizSettings = {
          customFields: [
            {
              resourceType: 'loads',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'dropdown',
                  dataOptions: [
                    'D301 NY1100 G1054 41ST ST DYCD JUL22-JUN25',
                    'D301 NY1200 G1088 BAINBR HYA 21-25 DYCD JUL22-JUN25',
                    'D301 NY1300 G1064 MC SHELTER DYCD JUL22-JUN25',
                    'D301 NY1110 G1241 41ST ST-AHT AMERICAN EAGLE OUT April 2025',
                    'D301 NY1100 G1245 41ST AHT BADGELEY TRUST $200K May 2025',
                    'D302 NY1510 G1086 ASJ-FEMALE DYCD JUL22-JUN25',
                    'D302 NY1600 G1087 FTELEY-MALE DYCD JUL22-JUN25',
                    'D302 NY1700 G1089 GLEBE-MALE DYCD JUL22-JUN25',
                    'D302 NY1500 G1092 ASJ-MC DYCD JUL22-JUN25',
                    'D303 NY1813 G1052 FAMILYCOV DOHMH JUL23-JUN25',
                    'D502 NY2100 G1263 HC AEOF MINDS PROGRAM JAN2025',
                    'D301 NY1300 G1269 MC ALFRED SMITH FDN JUNE 2025 W/ MH',
                    'D330 NY1000 G1036 COVWORKS DREAMS R US FDN Scholarships',
                    'D330 NY1000 G1113 COVWORKS ACCENTURE LLP',
                    'D330 NY1000 G1127 COVWORKS MEMTON FDN',
                    'D330 NY1000 G1232 COVWORKS HECKSHER FDN WORKFORCE 06/2025',
                    'D330 NY1000 G1264 COVWORKS METLIFE FDN 06/2025',
                    "D340 NY1000 G1217 THE PAPA JOHN'S FDN Survivor Leader Youth Ad",
                    'D320 NY1000 G1288 YOUTH INCENTIVES',
                    'D320 NY1000 G1247 ART SHOW PROCEEDS',
                    'D320 NY1000 G1286 HOLIDAY YOUTH GIFT CARDS',
                    'D102 NY1000 G1287 CHI YOUTH INCENTIVES',
                  ],
                },
              ],
            },
            {
              resourceType: 'financial_account_load',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'dropdown',
                  dataOptions: [
                    'D301 NY1100 G1054 41ST ST DYCD JUL22-JUN25',
                    'D301 NY1200 G1088 BAINBR HYA 21-25 DYCD JUL22-JUN25',
                    'D301 NY1300 G1064 MC SHELTER DYCD JUL22-JUN25',
                    'D301 NY1110 G1241 41ST ST-AHT AMERICAN EAGLE OUT April 2025',
                    'D301 NY1100 G1245 41ST AHT BADGELEY TRUST $200K May 2025',
                    'D302 NY1510 G1086 ASJ-FEMALE DYCD JUL22-JUN25',
                    'D302 NY1600 G1087 FTELEY-MALE DYCD JUL22-JUN25',
                    'D302 NY1700 G1089 GLEBE-MALE DYCD JUL22-JUN25',
                    'D302 NY1500 G1092 ASJ-MC DYCD JUL22-JUN25',
                    'D303 NY1813 G1052 FAMILYCOV DOHMH JUL23-JUN25',
                    'D502 NY2100 G1263 HC AEOF MINDS PROGRAM JAN2025',
                    'D301 NY1300 G1269 MC ALFRED SMITH FDN JUNE 2025 W/ MH',
                    'D330 NY1000 G1036 COVWORKS DREAMS R US FDN Scholarships',
                    'D330 NY1000 G1113 COVWORKS ACCENTURE LLP',
                    'D330 NY1000 G1127 COVWORKS MEMTON FDN',
                    'D330 NY1000 G1232 COVWORKS HECKSHER FDN WORKFORCE 06/2025',
                    'D330 NY1000 G1264 COVWORKS METLIFE FDN 06/2025',
                    "D340 NY1000 G1217 THE PAPA JOHN'S FDN Survivor Leader Youth Ad",
                    'D320 NY1000 G1288 YOUTH INCENTIVES',
                    'D320 NY1000 G1247 ART SHOW PROCEEDS',
                    'D320 NY1000 G1286 HOLIDAY YOUTH GIFT CARDS',
                    'D102 NY1000 G1287 CHI YOUTH INCENTIVES',
                  ],
                },
              ],
            },
            {
              resourceType: 'business_load_history',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'text',
                  dataOptions: [
                    'D301 NY1100 G1054 41ST ST DYCD JUL22-JUN25',
                    'D301 NY1200 G1088 BAINBR HYA 21-25 DYCD JUL22-JUN25',
                    'D301 NY1300 G1064 MC SHELTER DYCD JUL22-JUN25',
                    'D301 NY1110 G1241 41ST ST-AHT AMERICAN EAGLE OUT April 2025',
                    'D301 NY1100 G1245 41ST AHT BADGELEY TRUST $200K May 2025',
                    'D302 NY1510 G1086 ASJ-FEMALE DYCD JUL22-JUN25',
                    'D302 NY1600 G1087 FTELEY-MALE DYCD JUL22-JUN25',
                    'D302 NY1700 G1089 GLEBE-MALE DYCD JUL22-JUN25',
                    'D302 NY1500 G1092 ASJ-MC DYCD JUL22-JUN25',
                    'D303 NY1813 G1052 FAMILYCOV DOHMH JUL23-JUN25',
                    'D502 NY2100 G1263 HC AEOF MINDS PROGRAM JAN2025',
                    'D301 NY1300 G1269 MC ALFRED SMITH FDN JUNE 2025 W/ MH',
                    'D330 NY1000 G1036 COVWORKS DREAMS R US FDN Scholarships',
                    'D330 NY1000 G1113 COVWORKS ACCENTURE LLP',
                    'D330 NY1000 G1127 COVWORKS MEMTON FDN',
                    'D330 NY1000 G1232 COVWORKS HECKSHER FDN WORKFORCE 06/2025',
                    'D330 NY1000 G1264 COVWORKS METLIFE FDN 06/2025',
                    "D340 NY1000 G1217 THE PAPA JOHN'S FDN Survivor Leader Youth Ad",
                    'D320 NY1000 G1288 YOUTH INCENTIVES',
                    'D320 NY1000 G1247 ART SHOW PROCEEDS',
                    'D320 NY1000 G1286 HOLIDAY YOUTH GIFT CARDS',
                    'D102 NY1000 G1287 CHI YOUTH INCENTIVES',
                  ],
                },
              ],
            },
            {
              resourceType: 'funding_account_deposit',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'dropdown',
                  dataOptions: [
                    'D301 NY1100 G1054 41ST ST DYCD JUL22-JUN25',
                    'D301 NY1200 G1088 BAINBR HYA 21-25 DYCD JUL22-JUN25',
                    'D301 NY1300 G1064 MC SHELTER DYCD JUL22-JUN25',
                    'D301 NY1110 G1241 41ST ST-AHT AMERICAN EAGLE OUT April 2025',
                    'D301 NY1100 G1245 41ST AHT BADGELEY TRUST $200K May 2025',
                    'D302 NY1510 G1086 ASJ-FEMALE DYCD JUL22-JUN25',
                    'D302 NY1600 G1087 FTELEY-MALE DYCD JUL22-JUN25',
                    'D302 NY1700 G1089 GLEBE-MALE DYCD JUL22-JUN25',
                    'D302 NY1500 G1092 ASJ-MC DYCD JUL22-JUN25',
                    'D303 NY1813 G1052 FAMILYCOV DOHMH JUL23-JUN25',
                    'D502 NY2100 G1263 HC AEOF MINDS PROGRAM JAN2025',
                    'D301 NY1300 G1269 MC ALFRED SMITH FDN JUNE 2025 W/ MH',
                    'D330 NY1000 G1036 COVWORKS DREAMS R US FDN Scholarships',
                    'D330 NY1000 G1113 COVWORKS ACCENTURE LLP',
                    'D330 NY1000 G1127 COVWORKS MEMTON FDN',
                    'D330 NY1000 G1232 COVWORKS HECKSHER FDN WORKFORCE 06/2025',
                    'D330 NY1000 G1264 COVWORKS METLIFE FDN 06/2025',
                    "D340 NY1000 G1217 THE PAPA JOHN'S FDN Survivor Leader Youth Ad",
                    'D320 NY1000 G1288 YOUTH INCENTIVES',
                    'D320 NY1000 G1247 ART SHOW PROCEEDS',
                    'D320 NY1000 G1286 HOLIDAY YOUTH GIFT CARDS',
                    'D102 NY1000 G1287 CHI YOUTH INCENTIVES',
                  ],
                },
              ],
            },
          ],
        };
      } else if (
        businessId === 'ba7ecbc0-da3f-4020-ab19-f70eaaa57f64' ||
        store.state.baseBusiness.id === 'ba7ecbc0-da3f-4020-ab19-f70eaaa57f64'
      ) {
        // USCCB Business ID
        customBizSettings = {
          customFields: [
            {
              resourceType: 'loads',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'dropdown',
                  dataOptions: ['Per Capita Funds', 'Homelessness Prevention Funds', 'Other'],
                },
              ],
            },
            {
              resourceType: 'funding_account_deposit',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'dropdown',
                  dataOptions: ['Per Capita Funds', 'Homelessness Prevention Funds', 'Other'],
                },
              ],
            },
            {
              resourceType: 'financial_account_load',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'dropdown',
                  dataOptions: ['Per Capita Funds', 'Homelessness Prevention Funds', 'Other'],
                },
              ],
            },
            {
              resourceType: 'business_load_history',
              customFieldOptions: [
                {
                  label: 'Funding Source',
                  name: 'fundingSource',
                  dataType: 'text',
                  dataOptions: ['Per Capita Funds', 'Homelessness Prevention Funds', 'Other'],
                },
              ],
            },
          ],
        };
      }
      store.dispatch('updateBusinessSettings', customBizSettings);
      return customBizSettings;
    },

    refetchBusinessSpendRules(): void {
      getAuth()
        .currentUser?.getIdToken()
        .then((token) => {
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          const url = `${process.env.VUE_APP_WHIMSY_BASE_URL}/businesses/${store.state.business.id}/spendRules`;
          axios
            .get(url, { headers })
            .then((response) => {
              if (response !== undefined) {
                store.dispatch('updateBusinessSpendRules', response.data);
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                globalProperties.auth.friendly401Message();
              }
            });
        });
    },
    refetchCardholder(to: RouteLocationNormalized, userId: string): void {
      const user = getAuth().currentUser;
      user?.getIdToken().then(() => {
        const url = `${process.env.VUE_APP_WHIMSY_BASE_URL}/cardholders/${userId}/`;
        axios
          .get(url)
          .then((response) => {
            store.dispatch('updateCardholder', response.data.cardholder);
          })
          .catch((error) => {
            if (
              error.response &&
              (error.response.status === 401 || error.response.status === 404)
            ) {
              if (to.fullPath.includes('paybilt')) {
                globalProperties.auth.handleSignOutAndPushToPaybiltLanding();
              }
              globalProperties.auth.handleSignOutAndPushToCardholderLanding();
            }
          });
      });
    },

    refetchUserandBusiness(userId: string): void {
      const user = getAuth().currentUser;
      user?.getIdToken().then((token) => {
        const url = `${process.env.VUE_APP_WHIMSY_BASE_URL}/users/${userId}/`;
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .get(url, { headers })
          .then((response) => {
            if (store.state.business.id === undefined || '') {
              store.dispatch('updateBusiness', response.data.business);
            }
            store.dispatch('updateUser', response.data.user);
            store.dispatch('updateBaseBusiness', response.data.business);

            if (response.data.business.scope === 'super') {
              axios
                .get(
                  `${process.env.VUE_APP_WHIMSY_BASE_URL}/businesses/${response.data.business.id}/programs`,
                  { headers }
                )
                .then((programResponse) => {
                  let programs: BusinessProgram[] = [];
                  programs = programResponse.data.programs;
                  store.dispatch('updatePrograms', programs);
                })
                .catch((error) => {
                  if (error.response && error.response.status === 401) {
                    globalProperties.auth.friendly401Message();
                  } else if (
                    error.response &&
                    (error.response.status === 404 || error.response.status === 500)
                  ) {
                    globalProperties.auth.handleSignOutAndPushToLogin();
                  }
                });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              globalProperties.auth.friendly401Message();
            } else if (error.response && error.response.status === 404) {
              globalProperties.auth.handleSignOutAndPushToLogin();
            }
          });
        sessionStorage.removeItem('redirectPath');
      });
    },
    resetBusinessToBase(): void {
      store.dispatch('updateBusiness', store.state.baseBusiness);
    },
  },

  superBusiness: {
    getHomePath(): string {
      if (store.state.baseBusiness.scope === 'super') {
        return 'programs';
      }
      return 'cards';
    },

    getReportsPath(): string {
      if (store.state.baseBusiness.scope === 'super') {
        return 'superBusinessReports';
      }
      return 'reports';
    },
    getCurrentBusinessName(): string {
      if (store.state.business.scope === 'program') {
        return store.state.business.programName || store.state.business.legalBusinessName || '';
      }

      return store.state.business.legalBusinessName || '';
    },
    getCurrentProgramPayoutMethod(): BusinessProductType {
      return store.state.business.productType || BusinessProductType.cards;
    },
    hasSuperbusinessFundingAccount(): boolean {
      if (store.state.baseBusiness.scope === 'super') {
        if (
          store.state.baseBusiness.id === 'cd2ec786-fbae-4050-80bf-8f61f22c1166' || // Rynse Sandbox
          store.state.baseBusiness.superBusinessId === 'cd2ec786-fbae-4050-80bf-8f61f22c1166' ||
          store.state.baseBusiness.id === '4279ee42-4adc-45ad-a455-ab8e95ca966f' || // Samaritan Sandbox
          store.state.baseBusiness.superBusinessId === '4279ee42-4adc-45ad-a455-ab8e95ca966f' ||
          store.state.baseBusiness.id === '9db65bc0-30ff-4cfa-8d2b-67126c117118' || // Product Dev
          store.state.baseBusiness.superBusinessId === '9db65bc0-30ff-4cfa-8d2b-67126c117118' ||
          store.state.baseBusiness.id === 'aa422525-22ec-47b5-94cd-1554bed3f0e5' || // Erin Dev Business
          store.state.baseBusiness.superBusinessId === 'aa422525-22ec-47b5-94cd-1554bed3f0e5' ||
          store.state.baseBusiness.id === 'a8d54fb6-9046-4e22-8a7a-84ff02dc1b87' || // Pretty Dev
          store.state.baseBusiness.superBusinessId === 'a8d54fb6-9046-4e22-8a7a-84ff02dc1b87' ||
          store.state.baseBusiness.id === '2fe3c90a-5bf5-4343-a028-fe57bd8b8e8f' || // GCF Prod
          store.state.baseBusiness.superBusinessId === '2fe3c90a-5bf5-4343-a028-fe57bd8b8e8f' ||
          store.state.baseBusiness.id === '091977d1-1b60-44d5-aaed-090a24a63e95' || // Rynse
          store.state.baseBusiness.superBusinessId === '091977d1-1b60-44d5-aaed-090a24a63e95' ||
          store.state.baseBusiness.id === '3115a660-e611-4a2d-be35-b8a334ed2921' || // Samaritan
          store.state.baseBusiness.superBusinessId === '3115a660-e611-4a2d-be35-b8a334ed2921' ||
          store.state.baseBusiness.id === '4dcddb1e-be90-439f-ba83-a144f3cb5eea' || // Chapin Hall
          store.state.baseBusiness.superBusinessId === '4dcddb1e-be90-439f-ba83-a144f3cb5eea' ||
          store.state.baseBusiness.id === '6713b42a-a121-4c3a-b198-d72e5a291805' || // testing darryl
          store.state.baseBusiness.superBusinessId === '6713b42a-a121-4c3a-b198-d72e5a291805' ||
          store.state.baseBusiness.id === '13f79ce9-0999-4a24-aadb-5219c505f5b8' || // Miracle Messages
          store.state.baseBusiness.superBusinessId === '13f79ce9-0999-4a24-aadb-5219c505f5b8' ||
          store.state.baseBusiness.id === '93d55d1b-1c25-4ffa-82e5-8a8c5d8b3e20' || // Miracle Messages Sandbox
          store.state.baseBusiness.superBusinessId === '93d55d1b-1c25-4ffa-82e5-8a8c5d8b3e20'
        ) {
          return true;
        }
      }
      return false;
    },
  },

  fullStory: {
    updateUserVars(
      displayName?: string,
      email?: string,
      phoneNumber?: string,
      mostRecentPagePath?: string,
      mostRecentpageDescription?: string
    ): void {
      FullStory.setUserVars({
        displayName,
        email,
        phoneNumber,
        mostRecentPagePath,
        mostRecentpageDescription,
      });
    },
  },

  axios: {
    callAddMetaData(resourceType: string, resourceId: string, key: string, value: string): void {
      const {
        mutate: addMetadataCall,
        data: addMetadataData,
        error: addMetadataError,
        isLoading: addMetadataLoading,
      } = useAddMetadata();

      const params: AddMetadataParams = {
        type: resourceType as AddMetadataParamsType,
        targetId: resourceId,
        metadata: [
          {
            key,
            value,
          },
        ],
      };

      addMetadataCall({ data: params });
    },
  },
  methods,
  hasPermission,
});
export default globalProperties;
