
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    isIntakeLanguageRoute(): boolean {
      const intakeRoutes = ['recipientEmailVerification', 'recipientBankDetails'];
      return !!this.$route.name && intakeRoutes.includes(this.$route.name.toString());
    },
  },
  watch: {
    '$i18n.locale': {
      handler(newLocale: string) {
        // only updating the route if the current route is cardholders
        if (newLocale !== this.$route.query.lang && this.$route.name === 'cardholders') {
          this.$router.replace({ query: { ...this.$route.query, lang: newLocale } });
        }
      },
    },
  },
});
