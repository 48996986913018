import { User } from '@/api/models';
import store from '@/store/store';

/* eslint-disable no-shadow */
enum Role {
  Admin = 'admin',
  CWSCaseManager = 'cws_case_manager',
  USCCBCaseManager = 'usccb_case_manager',
  Viewer = 'viewer',
  USCCBFiscalAdministrator = 'usccb_fiscal_administrator',
  HIASCaseManager = 'hias_case_manager',
  CHCardManager = 'ch_card_manager',
  MMHotlineSupport = 'mm_hotline_support',
  chapinHallPIPermissions = 'chapin_hall_pi',
  chapinHallCardProgramManagerPermissions = 'chapin_hall_card_program_manager',
}

enum Permission {
  AddMember = 'addMember',
  DeleteMember = 'deleteMember',
  CreateProgram = 'createProgram',
  TransferFunds = 'transferFunds',
  LockCard = 'lockCard',
  SetPin = 'setPin',
  LoadCard = 'loadCard',
  OrderCard = 'orderCard',
  ViewACHDetails = 'viewACHDetails',
  DeveloperTools = 'developerTools',
  ModifyAutoload = 'modifyAutoload',
  CreateAutoload = 'createAutoload',
  CancelAutoload = 'cancelAutoload',
  AddCardsToAutoload = 'addCardsToAutoload',
  DeleteCardsFromAutoload = 'deleteCardsFromAutoload',
  AddTagToCard = 'addTagToCard',
  DeleteTagFromCard = 'deleteTagFromCard',
  CreateNote = 'createNote',
  EditNote = 'editNote',
  CreateSpendRule = 'createSpendRule',
  ModifySpendRule = 'modifySpendRule',
  CloseCard = 'closeCard',
  FreezeCard = 'freezeCard',
  ReplaceCard = 'replaceCard',
  ResendVirtualCard = 'resendVirtualCard',
  EditMetadata = 'editMetadata',
  HideTransactionData = 'hideTransactionData',
  CreateRecipient = 'createRecipient',
  SendBankPayment = 'sendBankPayment',
}

// Define role-permission mappings
const adminPermissions = [
  Permission.AddMember,
  Permission.DeleteMember,
  Permission.CreateProgram,
  Permission.TransferFunds,
  Permission.LockCard,
  Permission.SetPin,
  Permission.LoadCard,
  Permission.OrderCard,
  Permission.ViewACHDetails,
  Permission.DeveloperTools,
  Permission.ModifyAutoload,
  Permission.CreateAutoload,
  Permission.CancelAutoload,
  Permission.AddCardsToAutoload,
  Permission.DeleteCardsFromAutoload,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.CloseCard,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.ResendVirtualCard,
  Permission.EditMetadata,
  Permission.CreateRecipient,
  Permission.SendBankPayment,
];

const cwsCaseManagerPermissions = [
  Permission.OrderCard,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.ResendVirtualCard,
  Permission.EditMetadata,
];

const usccbCaseManagerPermissions = [
  Permission.LockCard,
  Permission.SetPin,
  Permission.OrderCard,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.ResendVirtualCard,
];

const usccbFiscalAdministratorPermissions = [
  Permission.AddMember,
  Permission.DeleteMember,
  Permission.TransferFunds,
  Permission.LockCard,
  Permission.SetPin,
  Permission.LoadCard,
  Permission.ViewACHDetails,
  Permission.DeveloperTools,
  Permission.ModifyAutoload,
  Permission.CreateAutoload,
  Permission.CancelAutoload,
  Permission.AddCardsToAutoload,
  Permission.DeleteCardsFromAutoload,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.CloseCard,
  Permission.FreezeCard,
  Permission.ResendVirtualCard,
  Permission.EditMetadata,
];

const hiasCaseManagerPermissions = [
  Permission.AddMember,
  Permission.CreateProgram,
  Permission.LockCard,
  Permission.SetPin,
  Permission.OrderCard,
  Permission.DeveloperTools,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.CloseCard,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.ResendVirtualCard,
  Permission.EditMetadata,
];

const chCardManagerPermissions = [
  Permission.LockCard,
  Permission.SetPin,
  Permission.LoadCard,
  Permission.AddTagToCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.CloseCard,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.EditMetadata,
  Permission.HideTransactionData,
];

const mmHotlineSupportPermissions = [
  Permission.LockCard,
  Permission.SetPin,
  Permission.OrderCard,
  Permission.AddTagToCard,
  Permission.CreateNote,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.ResendVirtualCard,
];

const chapinHallPIPermissions = [
  Permission.SetPin,
  Permission.LoadCard,
  Permission.OrderCard,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.CloseCard,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.ResendVirtualCard,
];

const chapinHallCardProgramManagerPermissions = [
  Permission.SetPin,
  Permission.LoadCard,
  Permission.OrderCard,
  Permission.AddTagToCard,
  Permission.DeleteTagFromCard,
  Permission.CreateNote,
  Permission.EditNote,
  Permission.CreateSpendRule,
  Permission.ModifySpendRule,
  Permission.FreezeCard,
  Permission.ReplaceCard,
  Permission.ResendVirtualCard,
];

// Create a map to store role-permission mappings
const mappings = new Map<string, Permission[]>();
mappings.set(Role.Admin, adminPermissions);
mappings.set(Role.CWSCaseManager, cwsCaseManagerPermissions);
mappings.set(Role.USCCBCaseManager, usccbCaseManagerPermissions);
mappings.set(Role.USCCBFiscalAdministrator, usccbFiscalAdministratorPermissions);
mappings.set(Role.HIASCaseManager, hiasCaseManagerPermissions);
mappings.set(Role.CHCardManager, chCardManagerPermissions);
mappings.set(Role.MMHotlineSupport, mmHotlineSupportPermissions);
mappings.set(Role.chapinHallPIPermissions, chapinHallPIPermissions);
mappings.set(Role.chapinHallCardProgramManagerPermissions, chapinHallCardProgramManagerPermissions);

function hasPermission(user: User, permission: string): boolean {
  if (!user?.role) {
    return false;
  }

  // Don't allow certain businesses to view transaction data
  if (
    permission === 'hideTransactionData' &&
    (store.state.baseBusiness.id === '0994be4d-63fc-42c8-a729-28cc613379a4' ||
      store.state.business.id === '0994be4d-63fc-42c8-a729-28cc613379a4')
  ) {
    return true;
  }

  const rolePermissions = mappings.get(user.role);
  return !!rolePermissions && rolePermissions.includes(permission as Permission);
}

export default hasPermission;
export { Role, Permission };
